<template>
  <div>
    <el-dialog
      :title="options.title ? options.title : '项目成员评分表'"
      :visible.sync="editShow"
      :close-on-click-modal="false"
      width="70%"
      :before-close="re"
    >
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        v-loading="loading"
      >
        <el-table-column type="index" label="序号" width="60" align="center" />
        <el-table-column align="center" prop="userName" width="100" label="员工姓名" />
        <el-table-column align="center" prop="examineMonth" width="100" label="考核月份" />
        <el-table-column
          align="center"
          prop="deptName"
          v-if="options.scoreType === 'manage'"
          label="部门名称"
        />
        <el-table-column
          align="center"
          prop="projectName"
          v-if="options.scoreType === 'project'"
          label="项目名称"
        />
        <el-table-column
          align="center"
          prop="businessName"
          v-if="options.scoreType === 'business'"
          label="商务名称"
        />
        <el-table-column align="center" prop="scoreRatio" label="评分系数" width="100" />
        <el-table-column align="center" prop="hourSalary" label="工时薪酬" />
      </el-table>
      <el-pagination
        style="margin-top: 5px"
        prev-text="上一页"
        next-text="下一页"
        layout="total, prev, pager, next, slot, jumper"
        :current-page="parameter.pageNow"
        :page-size="parameter.pageSize"
        :total="parameter.total"
        @size-change="handleSizeChange"
        @current-change="pageChangeHandler"
      >
        <span class="el-pagination__jump e_a_pagination">
          <el-input
            size="mini"
            v-model.number="pageSize"
            @blur="handlePageSize"
          ></el-input>
          <span style="padding-top: 1px">条/页</span>
        </span>
      </el-pagination>
      <div slot="footer" class="dialog-footer">
        <el-button @click="re">返 回</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  inject: ["re"],
  data() {
    return {
      loading: false,
      editShow: false,
      tableData: [],
      parameter: {
        order: "",
        orderRule: "",
        pageNow: 1,
        pageSize: 10,
        total: 0,
      },
      pageSize: 10,
    };
  },
  watch: {
    isShow: {
      handler(val) {
        this.editShow = val;
        if (val) {
          this.getTableData ();
        }
      },
    },
  },
  methods: {
    getTableData () {
      this.loading = true;
      let params = {
        scoreType: this.options.scoreType,
        userId: this.options.staffId,
        examineMonth: this.options.examineMonth,
      };
      Object.assign(this.parameter, params);
      this.$api.compensationManage
        .staffSalaryDetail(this.parameter)
        .then((res) => {
          //   console.log(res);
          this.tableData = res.data.records;
          this.parameter.total = res.data.total;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // handleCancel() {
    //   this.$emit("closeEdit", true);
    // },
    /**
     * 点击 "表格 -- 分页" 操作
     * @param page      当前页
     */
    pageChangeHandler(page) {
      this.parameter.pageNow = page;
      this.getTableData(); /** 加载 "发票申请" 数据 **/
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val;
      this.parameter.pageNow = 1;
      this.getTableData();
    },
    handlePageSize() {
      if (!this.pageSize) {
        this.pageSize = 1;
      }
      this.parameter.pageSize = this.pageSize;
      this.getTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog__header {
  border-bottom: 1px solid #ebeef5;
}
/deep/.el-dialog__body {
  padding: 20px;
}
/deep/.el-table__body-wrapper {
  max-height: 45vh;
  overflow: auto;
}
</style>
